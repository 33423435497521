<template>
  <div class="container">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Добавить тренера</h1>
      </div>
    </div>
    <TrainerEditForm ref="userAddForm" />
    <form class="form">
      <div class="form__btn-gp">
        <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
        <router-link :to="{name: this.$routeName.TRAINER_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
      </div>
    </form>
  </div>
</template>

<script>


import TrainerEditForm from "@component/Form/TrainerEditForm";

export default {
  name: "TrainerAdd",
  components: {
    TrainerEditForm,
  },
  data() {
    return {};
  },
  methods: {

    async save() {
      let formData = this.$refs.userAddForm.formSubmitGetData();
      if(!formData) { return; }

      // { username, mobile, first_name, last_name, middle_name, about }
      let sendData = Object.assign({}, formData);

      sendData.mobile = sendData.mobile.replace(/-|\s+/g,"");
      //
      sendData.contact_set = [];

      if(sendData.contactTelegram  ) { sendData.contact_set.push({ type: "TGRM", value: sendData.contactTelegram  }); }
      if(sendData.contactEmail     ) { sendData.contact_set.push({ type: "MAIL", value: sendData.contactEmail     }); }
      if(sendData.contactTwitter   ) { sendData.contact_set.push({ type: "TWTR", value: sendData.contactTwitter   }); }
      if(sendData.contactWhatsapp  ) { sendData.contact_set.push({ type: "WTSP", value: sendData.contactWhatsapp  }); }
      if(sendData.contactInstagram ) { sendData.contact_set.push({ type: "INST", value: sendData.contactInstagram }); }

      delete sendData.contactTelegram;
      delete sendData.contactEmail;
      delete sendData.contactTwitter;
      delete sendData.contactWhatsapp;
      delete sendData.contactInstagram;


      RequestManager.Trainer.addTrainer(sendData).then( (res) => {

        if(sendData.slotEnable) {

          let data = {
            owner     : res.self_family,
            status    : 'FREE',
            // span      : sendData.span,
            // repeat    : sendData.repeat.id,
            date      : sendData.date,
            start_time: sendData.start_time,
            end_time  : sendData.end_time,
          };

          if(sendData.repeat.id !== 'ONCE') {
            data.repeat = sendData.repeat.id;
            data.span   = sendData.span.id;
          }

          RequestManager.Trainer.addTrainerSlot({
            trainerId: res.id,
            data     : data
          });
        }

        this.$toasts.push({
          message: 'Тренер успешно добавлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.TRAINER_LIST });
      });

    }
  }
};
</script>

<style lang="scss" scoped>

</style>
